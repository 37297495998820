
import { defineComponent, ref, watch, onMounted, Ref } from "vue";

import useEmitter from "@/composables/Emmiter";
import { useStore } from "vuex";
import FilterBase from "@/layout/header/partials/filters/FilterBase.vue";
import Api from "@/services/Api";

interface StoreFilter {
  grupo: number | null;
  loja: any[];
  data: any[];
  regiao: any[];
}

export default defineComponent({
  name: "filtro-dashboard",
  components: {
    FilterBase,
  },

  setup() {
    const emitter = useEmitter();

    const closeModal = ref(false);
    const storeGroups: Ref<any[]> = ref([]);
    const lojas: Ref<any[]> = ref([]);
    const regioes: Ref<any[]> = ref([]);
    const loadingLojas: Ref<boolean> = ref(false);

    const storeFilter: Ref<StoreFilter> = ref({ grupo: null, loja: [], data: [], regiao: [] });

    async function getStoreGroups() {
      try{
        const { data } = await Api.get("getAllStoreGroup");
        storeGroups.value = data.grupos;

      }catch(error){
        console.log(error)
      }
    }

    async function getLojas(idGrupo: number | null) {
      loadingLojas.value = true
      try{
        const res = await Api.get(`getAllStores?idGrupo=${idGrupo}`);
        lojas.value = res.data.lojas;
      }catch(error){
        console.log(error)
      }finally{
        loadingLojas.value = false
      }
    }

    async function getRegioes() {
      const { data } = await Api.get("getRegioes");
      regioes.value = data.regioes;
    }

    async function enviarEmit() {
      await emitter.emit("filtro-dashboard", {
        grupoEloja: storeFilter.value,
      });
      closeModal.value = !closeModal.value;
    }

    emitter.on("Dashboard", async params => {
      storeFilter.value.data = params.dataSelecionada;
    });

    watch(
      () => storeFilter.value.grupo,
      () => {
        lojas.value = []
        getLojas(storeFilter.value.grupo);
      }
    );

    onMounted(() => {
      getStoreGroups();
      getRegioes();
    });

    return { storeFilter, storeGroups, lojas, enviarEmit, closeModal, regioes, loadingLojas };
  },
});
